import { Component } from "react"
import { withRouter } from 'react-router'
import { Button, message, Input, Form } from 'antd';
import axios from "axios";
import styles from './style.module.css'
import { LIMApp } from "../..";
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 6, span: 16 },
}
interface LoginState  {
    loading: boolean
    name: string
    pwd: string
}

class Login extends Component<any, LoginState>{
    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            pwd: '',
        }
    }
    submit(name: string, pwd: string) {

        this.setState({ loading: true })

        let then = this
        axios.post('/manager/login', {
            username: name,
            password: pwd,
        }).then(function (response) {
                let loginInfo = LIMApp.shared().loginInfo
                loginInfo.name = response.data.name
                loginInfo.uid = response.data.uid
                loginInfo.token = response.data.token
                loginInfo.role = response.data.role
                loginInfo.save()
                then.setState({ loading: false })
                if(then.props.onLoginSuccess) {
                    then.props.onLoginSuccess()
                }
                then.props.history.push("/welcome")
            })
            .catch(function (error) {
                message.error(error.msg)
                then.setState({ loading: false })
            });
    }
    onFinish(value: any) {
        const password = value.password
        const username = value.username
        this.submit(username, password)
    }
    onFinishFailed() {

    }
    render() {
        const { loading } = this.state

        return (
            <div className={styles.home} style={{ background: "url(/login_bg.svg)" }}>
                <div className={styles.loginBox}>
                    <div>
                        <img className={styles.logo} src="/lvlogo.png" alt='' />
                        <label className={styles.title}>绿讯通</label>
                    </div>
                    <div className={styles.inputBody}>
                        <Form name="basic"
                            {...layout}
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed.bind(this)}>
                            <Form.Item label='账号' name='username' hasFeedback rules={[
                                { required: true, message: '用户名不能为空' }
                            ]}>
                                <Input placeholder='请输入用户名' />
                            </Form.Item>
                            <Form.Item label='密码' name='password' hasFeedback rules={[
                                { required: true, message: '密码不能为空' }
                            ]}>
                                <Input.Password placeholder='请输入密码' />
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit" style={{ width: '100%'}} loading={loading}>
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>


                </div>


            </div>
        )
    }
}


export default Login