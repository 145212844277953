import { Layout, Menu, Dropdown } from "antd"
import { Component, ElementType } from "react"
import {
    TeamOutlined,
    UserOutlined,
    PoweroffOutlined,
    MessageOutlined,
    RedEnvelopeOutlined,
    SettingOutlined,
    AlertOutlined,
    ToolOutlined,
    HomeOutlined,
} from '@ant-design/icons';

import styles from './style.module.css'
import { LIMApp, LIMRoute } from "../modules";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
interface HomeState {
    collapsed: boolean
    homeTitle: string
    loginUserName: string
    logingUserAvatar: string
}
class HomeLayout extends Component<any, HomeState>{
    login: ElementType

    constructor(props: any) {
        super(props)
        const route: LIMRoute = LIMApp.shared().invoke('/login')
        this.login = route.component
        this.state = {
            collapsed: false,
            homeTitle: '绿讯通',
            loginUserName: '',
            logingUserAvatar: '',
        }
    }
    componentDidUpdate(nextProps: any) {
        console.log(nextProps)
    }

    componentDidMount() {
        this.resetData()
    }
    onCollapse(collapsed: boolean) {
        console.log(collapsed);
        if (collapsed) {
            this.setState({ homeTitle: '' })
        } else {
            const less = this
            setTimeout(function () {
                less.setState({ homeTitle: '绿讯通' })
            }, 200);
        }
        this.setState({ collapsed })
    };
    handleMenuClick() {
        const loginInfo = LIMApp.shared().loginInfo
        loginInfo.uid = ''
        loginInfo.name = ''
        loginInfo.token = ''
        LIMApp.shared().loginInfo.save()
        this.setState({
            loginUserName: ''
        })
        this.props.history.push("/login");
    }
    toggleCollapsed() {
        this.setState({ collapsed: !this.state.collapsed })
        if (this.state.collapsed) {
            this.setState({ homeTitle: '绿讯通' })
        } else {
            this.setState({ homeTitle: '' })
        }
    }
    resetData() {
        const name = LIMApp.shared().loginInfo.name
        const avatar = LIMApp.shared().getAvatarUrl(LIMApp.shared().loginInfo.uid!, LIMApp.shared().channelPersonal)
        this.setState(
            {
                collapsed: false,
                homeTitle: '绿讯通',
                loginUserName: name || '',
                logingUserAvatar: avatar
            }
        )
    }
    render() {
        const { login: Login } = this;
        if (!LIMApp.shared().isLogined()) {
            return <Login onLoginSuccess={() => {
                this.resetData()
            }} />
        }
        const menu = (
            <Menu onClick={this.handleMenuClick.bind(this)}>
                <Menu.Item key="1" icon={<PoweroffOutlined />}>
                    退出登录
                </Menu.Item>
            </Menu>
        )

        const { collapsed, homeTitle, loginUserName, logingUserAvatar } = this.state
        return (
            <Layout style={{ minHeight: '100%' }} >
                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse.bind(this)}>
                    <div className={styles.home_logo} >
                        <img alt='' className={styles.logo} src="/lvlogo.png" />
                        <label className={styles.title}>{homeTitle}</label>
                    </div>
                    <Menu theme="dark" defaultSelectedKeys={['welcome']} mode="inline">
                        <Menu.Item key="welcome" isSelected={true} icon={<HomeOutlined />} onClick={() => {
                            this.props.history.push("/welcome");
                        }}>
                            首页
                        </Menu.Item>
                        <SubMenu key="sub1" icon={<UserOutlined />} title="用户管理">
                            <Menu.Item key="3" onClick={() => {
                                this.props.history.push("/adduser");
                            }}>添加用户</Menu.Item>
                            <Menu.Item key="userlist" onClick={() => {
                                this.props.history.push("/userlist");
                            }}>用户列表</Menu.Item>
                            <Menu.Item key="disablelist" onClick={() => {
                                this.props.history.push('/disablelist')
                            }}>封禁用户列表</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" icon={<TeamOutlined />} title="群组管理">
                            <Menu.Item key="grouplist" onClick={() => {
                                this.props.history.push('/grouplist')
                            }}>群列表</Menu.Item>
                            <Menu.Item key="groupdisablelist" onClick={() => {
                                this.props.history.push('/groupdisablelist')
                            }}>封禁群列表</Menu.Item>
                        </SubMenu>
                        <SubMenu key='msgManager' icon={<MessageOutlined />} title='消息管理'>
                            <Menu.Item key='sendmsglist' onClick={() => {
                                this.props.history.push('/sendmsglist')
                            }}>
                                消息发送记录
                            </Menu.Item>
                            <Menu.Item key="prohibitwords" onClick={() => {
                                this.props.history.push('/prohibitwords')
                            }}>
                                违禁词列表
                            </Menu.Item>

                        </SubMenu>

                        <Menu.Item key='report' icon={<AlertOutlined />} onClick={() => {
                            this.props.history.push('/reportlist')
                        }}>
                            举报管理
                        </Menu.Item>
                        <SubMenu key='tool' icon={<ToolOutlined />} title='工具'>
                            <Menu.Item key='appUpdate' onClick={() => {
                                this.props.history.push('/appupdate')
                            }}>
                                APP升级
                            </Menu.Item>
                            <Menu.Item key='systemRobotMenu' onClick={() => {
                                this.props.history.push('/systemrobotmenu')
                            }}>
                                系统机器人菜单管理
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key='userSetting' icon={<SettingOutlined />} title='设置'>
                            <Menu.Item key="currencysetting" onClick={() => {
                                this.props.history.push('/currencysetting')
                            }}>
                                通用设置
                            </Menu.Item>
                            <Menu.Item key='updateLoginPwd' onClick={() => {
                                this.props.history.push('/updatepwd')
                            }}>
                                修改登录密码
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout className="site-layout">

                    <Header className="site-layout-background" style={{ padding: 0 }} >

                        <div className={styles.title_right}>
                            <Dropdown overlay={menu}>
                                <span className={styles.login_layout}>
                                    <img alt='' className={styles.login_avatar} src={logingUserAvatar} />
                                    <label className={styles.login_name}>{loginUserName}</label>
                                </span>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            {this.props.children}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        )
    }
}

export default HomeLayout