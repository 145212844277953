import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";

import styles from './style.module.css'
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 6, span: 16 },
}
interface ChangeUserPwdState {
    data: any[]
    uid: string
    username: string
    spinning: boolean
    selectedFriends: any[]
    selectedRowKeys: React.Key[]
    visible: boolean
    content: string,
    loading: boolean,
    surepwd: string,
    newpwd:string,
    oldpwd:string
}

class ChangeUserPwd extends Component<any, ChangeUserPwdState>{
    constructor(props: any) {
        debugger
        super(props)
        this.state = {
            data: [],
            spinning: true,
            uid: this.props.location.state.uid,
            username: this.props.location.state.username,
            visible: false,
            content: '',
            selectedFriends: [],
            selectedRowKeys: [],
            loading: false,
            surepwd: '',
            newpwd:'',
            oldpwd:''
        }
    }
    onFinish(value: any) {
        const newpwd = value.newpwd
        const uid = value.uid
        this.save( uid,newpwd)
    }
    onFinishFailed(v: any) {
        console.log(v)
    }
    save(uid:string,newPwd: string) {
        this.setState({
            loading: true
        })
        const then = this
        const that = this
        axios.post('apiext/user/pwd/'+uid, {
            'password': newPwd,
        }).then(function (resp) {
            then.setState(
                {
                    data: [],
                    spinning: true,
                    uid: '',
                    username: '',
                    visible: false,
                    content: '',
                    selectedFriends: [],
                    selectedRowKeys: [],
                    loading: false,
                    surepwd: '',
                    newpwd:'',
                    oldpwd:''
                }
            )
            message.success('修改成功')
            that.props.history.push("/userlist",{})
        }).catch(function (error) {
            message.error(error.msg)
            then.setState({
                loading: false,
            })
        })
    }
    render() {
        const { uid,username,loading, surepwd, newpwd } = this.state
        debugger
        return (
            <div className={styles.body}>
                <p style={{ marginBottom: '20px' }}>修改登录密码</p>
                <Form style={{ width: '30%' }} {...layout}
                    name="basic"
                    initialValues={{ remember: true ,uid:uid,username:username}}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}>
                    <Form.Item label='uid' name='uid'>
                        <Input value={uid} disabled />
                    </Form.Item>
                    <Form.Item label='昵称' name='username'>
                        <Input value={username} disabled />
                    </Form.Item>
                    <Form.Item label='新密码' name='newpwd' hasFeedback rules={[
                        { required: true, message: '请输入新密码' },
                    ]}>
                        <Input.Password value={newpwd} />
                    </Form.Item>
                    <Form.Item hasFeedback label='确认密码' name='surepwd' dependencies={['newpwd']} rules={[
                        { required: true, message: '请输入确认密码' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newpwd') === value) {
                                    return Promise.resolve()
                                }
                                if (value.length < 6) {
                                    return Promise.reject(new Error('密码长度必须大于6位!'))
                                }
                                return Promise.reject(new Error('新密码和确认密码不一致!'))
                            },
                        }),
                    ]}>
                        <Input.Password value={surepwd} />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            提交
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        )
    }
}

export default ChangeUserPwd