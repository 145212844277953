import Cookies from 'js-cookie'
import { LIMMethodManager, LIMMethodOptions } from './base/method'
import { LIMModule, LIMModuleManager } from './base/module'
import axios from 'axios'

export class LIMConfig {
    appName: string = "绿讯通"
    // apiURL: string = "http://1.94.42.105:8081/v1/"
    apiURL: string = "/api/v1/"
}

export class LIMApp {
    private static instance: LIMApp
    public loginInfo!: LoginInfo
    private methodManager!: LIMMethodManager
    private moduleManager!: LIMModuleManager
    public config!: LIMConfig // app配置
    public channelPersonal: number = 1
    public channelGroup: number = 2
    public sytemUserUID: string = 'u_10000'
    static shared() {
        if (!this.instance) {
            this.instance = new LIMApp()
            this.instance.methodManager = new LIMMethodManager()
            this.instance.moduleManager = new LIMModuleManager()
            this.instance.loginInfo = new LoginInfo()
            this.instance.config = new LIMConfig()
        }
        return this.instance
    }
    init() {
        this.loginInfo.load()
        this.initAxios()
    }
    getAvatarUrl(channelID: string, channelType: number): string {
        if (channelType === 1) {
            return this.config.apiURL + "users/" + channelID + "/avatar"
        }
        return this.config.apiURL + "groups/" + channelID + "/avatar"
    }
    getShowURL(path: string): string {
        if (path === '' || path.toLowerCase().startsWith('http')) {
            return path
        }
        return this.config.apiURL + path
    }
    // 是否登录
    isLogined() {
        return this.loginInfo.isLogined()
    }
    // 退出
    logout() {
        this.loginInfo.logout()
    }
    // 注册路由
    registerRoute(route: LIMRoute) {
        this.setMethod(route.path, () => route, new LIMMethodOptions("route"))
    }

    getAllRoute(): Array<LIMRoute> {
        return this.invokes("route")
    }

    initAxios() {
        axios.defaults.baseURL = this.config.apiURL;
        const self = this;
        axios.interceptors.request.use(function (config) {
            if (self.loginInfo.token) {
                config.headers["token"] = self.loginInfo.token;
            }
            return config;
        });
        let then = this
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            var msg = "";
            switch (error.response && error.response.status) {
                case 400:
                    msg = error.response.data.msg;
                    break;
                case 404:
                    msg = "请求地址没有找到（404）"
                    break;
                case 401:
                    then.logout()
                    msg = "登录认证过期，请重新登录"
                    break;
                default:
                    msg = "未知错误"
                    break;
            }
            return Promise.reject({ error: error, msg: msg, status: error?.response?.status });
        });
    }

    setMethod(sid: String, handler: Function, opts: LIMMethodOptions = new LIMMethodOptions()) {
        this.methodManager.setMethod(sid, handler, opts)
    }

    invoke(sid: String): any {
        return this.methodManager.invoke(sid)
    }

    invokes(category: String, param: any = undefined): Array<any> {
        return this.methodManager.invokes(category, param)
    }

    registerModule(m: LIMModule) {
        this.moduleManager.registerModule(m)
    }

    getAllModules(): Array<LIMModule> {
        return this.moduleManager.getAllModule()
    }
}

// 登录信息
export class LoginInfo {
    token?: string
    uid?: string
    name?: string
    role?: string
    load() {
        this.token = Cookies.get('token')
        this.uid = Cookies.get('uid')
        this.name = Cookies.get('name')
        this.role = Cookies.get('role')
    }
    isLogined() {
        if (this.token === '' || !this.token) {
            return false
        }
        return true
    }

    save() {
        Cookies.set('token', this.token ?? '')
        Cookies.set('name', this.name ?? '')
        Cookies.set('uid', this.uid ?? '')
        Cookies.set('role', this.role ?? '')
    }

    logout() {
        this.token = undefined
        Cookies.remove('token')
    }
}


export class LIMRoute {
    path: string = ''
    component!: React.ComponentType
    constructor(path: string, component: React.ComponentType) {
        this.path = path
        this.component = component
    }

}
